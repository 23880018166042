<template>
  <v-container class="pa-0">
    <v-card class="rounded-lg pt-2 shadow-regular">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col class="my-2 section-title py-0" cols="auto">
              {{ t('Action_.selectMenu.3') }}
            </v-col>
            <v-col class="text-right py-0">
              <v-btn
                data-testid="ticket-upload-btn"
                v-if="canUser(['Fleet.Ticket.CreateTicket'])"
                class="upload-file-btn"
                @click="toggleDisplayDialog()"
              >
                {{ t('Action_.buttons.uploadTicket') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-2 py-0">
              <v-btn-toggle
                class="d-flex justify-left mb-3 doc-filter-container"
                color="blueRegular"
                group
                borderless
                dense
                mandatory
              >
                <v-btn
                  data-testid="documents-uploaded-btn"
                  class="doc-filter rounded-lg"
                  @click="setActiveTab('documents')"
                >
                  {{ t('Title_.subTitle.uploadedDocuments') }}
                </v-btn>
                <v-btn
                  v-if="canUser(['Fleet.Ticket.ReadTicket'])"
                  data-testid="documents-tickets"
                  class="doc-filter rounded-lg"
                  @click="setActiveTab('tickets')"
                >
                  {{ t('Title_.subTitle.tickets') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row v-if="activeTab === 'documents'">
            <v-col cols="12" class="pa-0">
              <FleetUploadedDocuments
                :display-dialog="displayDialog"
                @close-ticket-dialog="closeTicketDialog"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" class="pa-0">
              <FleetTickets
                :display-dialog="displayDialog"
                @close-ticket-dialog="closeTicketDialog"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup>
import languages from '../locales/languages';
import FleetUploadedDocuments from './fleetDocuments/fleet-uploaded-documents.vue';
import FleetTickets from './fleetDocuments/fleet-tickets.vue';
import { usePermissions } from '@/helpers/composables/permissions.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, defineEmits } from 'vue';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const fleetVehiclesStore = useFleetVehiclesStore();
const { canUser } = usePermissions();
const emit = defineEmits(['update-list']);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const activeTab = ref('documents');
const displayDialog = ref(false);

// --- Methods ---
const toggleDisplayDialog = () => {
  displayDialog.value = !displayDialog.value;
  if (displayDialog.value) {
    trackPage(fleetVehiclesStore.vehicleDetails.hubId, {
      routeName: 'fleet.vehicleDetails.documents.uploadTicket',
    });
  }
};

const closeTicketDialog = () => {
  toggleDisplayDialog();
  emit('update-list');
};

const setActiveTab = (tab) => {
  const tabEventName =
    tab == 'documents'
      ? 'fleet.vehicleDetails.documents.uploadedDocuments'
      : 'fleet.vehicleDetails.documents.tickets';
  activeTab.value = tab;
  trackPage(fleetVehiclesStore.vehicleDetails.hubId, {
    routeName: tabEventName,
  });
};
</script>

<style scoped lang="scss">
.doc-filter-container {
  overflow: auto;

  .doc-filter {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.3px;
    height: 2rem !important;
    padding: 0 6px !important;
  }
}
</style>
