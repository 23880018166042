<template>
  <v-container data-testid="fleet-ticket-form">
    <v-dialog
      :value="displayDialog"
      :retain-focus="false"
      persistent
      width="750"
      class="pa-4"
    >
      <v-card class="pa-4">
        <v-container>
          <p class="card-title pa-0 mb-4 font-weight-bold">
            {{
              t('Msg_.tickets.addTicket') +
              fleetVehiclesStore.vehicleDetails.plate
            }}
          </p>
          <v-form ref="form" class="mt-6" v-model="valid">
            <v-row>
              <v-col cols="6">
                <RegularTextInput
                  :label="t('Msg_.tickets.labels.refId') + '*'"
                  v-model="ticketPayload.referenceNumber"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.tickets.labels.refId')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  :label="t('Msg_.tickets.labels.responsible')"
                  :value="userStore.userInfo.name"
                  :disabled="true"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0">
                <p class="custom-label mb-1 mt-0">
                  {{ t('Msg_.tickets.labels.employee') + '*' }}
                </p>
                <v-autocomplete
                  v-model="ticketPayload.employeeId"
                  :items="items"
                  :search-input.sync="search"
                  dense
                  outlined
                  hide-no-data
                  append-icon=""
                  prepend-inner-icon="mdi-magnify"
                  color="blueRegular"
                  item-text="completeName"
                  item-value="id"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.tickets.labels.employee')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                >
                  <template #item="data">
                    <v-list-item-content>
                      <v-list-item-title class="employee-name">
                        {{ data.item.completeName }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="employee-office">
                        {{ data.item.office }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="pt-0">
                <DatePicker
                  :label="t('Msg_.tickets.labels.date') + '*'"
                  v-model="ticketPayload.ticketDate"
                  :max="nowDate"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.tickets.labels.date')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0">
                <DropdownInput
                  :label="t('Msg_.tickets.labels.type') + '*'"
                  :items="selectTicketType"
                  item-text="text"
                  item-value="value"
                  v-model="ticketPayload.ticketType"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.tickets.labels.type')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6" class="pt-0">
                <FileInput
                  :label="t('Msg_.tickets.labels.file') + '*'"
                  :value="fileName"
                  :placeholder="$helpers.shrinkString(fileName)"
                  @input="createDocument($event)"
                  :clearable="true"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.tickets.labels.file')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <TextArea
                  :label="t('Msg_.tickets.labels.comments')"
                  v-model="ticketPayload.comment"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pl-0 pt-0">
                <v-btn
                  class="cancel-variant-btn ml-3"
                  @click="closeDialog()"
                  :disabled="isTicketPostInProgress || isUploadInProgress"
                >
                  {{ t('Action_.buttons.cancel') }}
                </v-btn>
                <v-btn
                  class="main-action-btn ml-2"
                  :disabled="
                    !valid || isTicketPostInProgress || isUploadInProgress
                  "
                  :loading="isTicketPostInProgress"
                  @click="submit()"
                >
                  {{ t('Action_.buttons.save') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import FileInput from '@/components/shared/customComponents/inputs/file-input.vue';
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import TextArea from '@/components/shared/customComponents/inputs/text-area.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import languages from '../../locales/languages';
import { useUserStore } from '@/store/pinia/user.store.js';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { usePeopleEmployeesStore } from '@/store/pinia/people-employees.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import rules from '@/helpers/rules';
import { ref, defineProps, defineEmits, computed, watch } from 'vue';

const userStore = useUserStore();
const fleetVehiclesStore = useFleetVehiclesStore();
const peopleEmployeesStore = usePeopleEmployeesStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits(['close-ticket-dialog']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  displayDialog: {
    type: Boolean,
    required: true,
  },
});

// --- Local variables ---
const nowDate = new Date().toISOString().slice(0, 10);
const items = ref([]);
const search = ref(null);
const select = ref(null);
const valid = ref(false);
const fileName = ref(null);
const isTicketPostInProgress = ref(false);
const isUploadInProgress = ref(false);
const ticketPayload = ref({
  id: '',
  referenceNumber: null,
  vehicleId: null,
  employeeId: null,
  ticketURL: null,
  comment: null,
  ticketType: null,
  ticketDate: null,
});

// --- Methods ---
const submit = async () => {
  ticketPayload.value.vehicleId = fleetVehiclesStore.vehicleDetails.id;

  // const toastParams = {
  //   type: 'success',
  // };
  // this.$root.message.setToastParams(toastParams);

  try {
    isTicketPostInProgress.value = true;
    await fleetVehiclesStore.createTicket(ticketPayload.value);
    // this.$root.message.showToastNotification();
  } catch (e) {
    console.log(e);
  } finally {
    isTicketPostInProgress.value = false;
    closeDialog();
  }
};

const querySelections = async (data) => {
  const user = await peopleEmployeesStore.getEmployeesByQuery(data);
  let filteredUsers = user.items.filter((e) => {
    return (
      (e.completeName || '').toLowerCase().indexOf((data || '').toLowerCase()) >
      -1
    );
  });

  items.value = filteredUsers.map((user) => {
    if (user.mainHub) {
      user.office = user.mainHub;
    } else if (user.offices.length > 0) {
      user.office = user.offices[0];
    } else {
      user.office = 'No Hub';
    }

    return user;
  });
};

const createDocument = async (file) => {
  if (file) {
    // const toastParams = {
    //   type: 'error',
    // };
    // this.$root.message.setToastParams(toastParams);

    try {
      isUploadInProgress.value = true;
      const ticketUrl = await fleetVehiclesStore.uploadVehicleTicket(file);
      ticketPayload.value.ticketURL = ticketUrl;
    } catch (e) {
      console.log(e);
      ticketPayload.value.ticketURL = null;
    } finally {
      isUploadInProgress.value = false;
    }
  }
};

const closeDialog = () => {
  emit('close-ticket-dialog');
};

// --- Computed ---
const selectTicketType = computed(() => {
  let actionSelectTicketType = tm('Action_.selectTicketType');

  return helpers.translateAndAdaptToVuetify(
    actionSelectTicketType,
    fleetVehiclesStore.vehicleResources.ticketTypes
  );
});

// --- Watchers ---

watch(
  () => search.value,
  (val) => {
    val !== select.value && querySelections(val);
  }
);
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;
}

.employee-name {
  font-weight: 300 !important;
  font-size: 13px !important;
  letter-spacing: 0.4px;
  color: $mine-black;
  &.no-hub {
    color: $grey-regular !important;
  }
}

.employee-office {
  font-weight: 500 !important;
  font-size: 11px !important;
  color: $grey-light !important;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  &.no-hub {
    color: $red-danger !important;
  }
}
.v-input--is-focused ::v-deep error--text {
  border: 1px solid $blue-regular !important;
}

.v-text-field--outlined ::v-deep fieldset {
  border-color: $ash-dark;
}
</style>
