<template>
  <v-container class="pa-0">
    <v-data-table
      class="px-4"
      hide-default-footer
      :headers="headers"
      :items="allVehicleDocs"
      data-testid="fleet-uploaded-documents"
      :no-data-text="t('Msg_.noDataAvailable')"
    >
      <template #[`item.date`]="{ item }">
        <p class="mb-0" data-testid="item-date">
          {{ helpers.formatDate(item.date) || '-' }}
        </p>
      </template>
      <template #[`item.renewalDate`]="{ item }">
        <p class="mb-0" data-testid="item-renewalDate">
          {{ helpers.formatDate(item.renewalDate) || '-' }}
        </p>
      </template>

      <template class="text-right" #[`item.interact`]="{ item }">
        <IconButton
          class="icon-btn"
          icon="mdi-eye"
          :disabled="!item.hasDocument"
          @click="interactWithDocument(item.type, false)"
        />
        <IconButton
          class="icon-btn ml-2"
          icon="mdi-download"
          :disabled="!item.hasDocument"
          @click="interactWithDocument(item.type, true)"
        />
      </template>
    </v-data-table>
    <FleetTicketForm
      v-if="displayDialog"
      :display-dialog="displayDialog"
      @close-ticket-dialog="closeTicketDialog"
    />
  </v-container>
</template>

<script setup>
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import languages from '../../locales/languages';
import FleetTicketForm from './fleet-ticket-form.vue';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import helpers from '@/helpers/helpers';
import { defineEmits, defineProps, computed } from 'vue';

const fleetVehiclesStore = useFleetVehiclesStore();
const { t } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits(['close-ticket-dialog']);

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  displayDialog: {
    type: Boolean,
    required: true,
  },
});

// --- Methods ---
const closeTicketDialog = () => {
  emit('close-ticket-dialog');
};

const interactWithDocument = (type, download) => {
  if (download) {
    window.open(
      `${process.env.VUE_APP_LOGIN_API}/v2/api/Vehicles/${vehicleId.value}/document/${type}?download=true`
    );
  } else {
    window.open(
      `${process.env.VUE_APP_LOGIN_API}/v2/api/Vehicles/${vehicleId.value}/document/${type}/`,
      '_blank'
    );
  }
};

// --- Computed ---
const vehicleId = computed(() => {
  return fleetVehiclesStore.vehicleDetails?.id;
});

const allVehicleDocs = computed(() => {
  return [
    {
      field: t('Action_.buttons.carIdFile'),
      date: fleetVehiclesStore.vehicleDetails?.carIdRegistrationDate,
      renewalDate: fleetVehiclesStore.vehicleDetails?.carIdRenewalDate,
      type: fleetVehiclesStore.vehicleDetails?.carIdDocument?.type,
      hasDocument:
        !!fleetVehiclesStore.vehicleDetails?.carIdDocument?.fileName || false,
    },
    {
      field: t('Action_.buttons.tuvAuFile'),
      date: fleetVehiclesStore.vehicleDetails?.streetLegalDate,
      renewalDate: fleetVehiclesStore.vehicleDetails?.streetLegalRenewalDate,
      type: fleetVehiclesStore.vehicleDetails?.streetLegalDocument?.type,
      hasDocument:
        !!fleetVehiclesStore.vehicleDetails?.streetLegalDocument?.fileName ||
        false,
    },
    {
      field: t('Action_.buttons.inspectionFile'),
      date: fleetVehiclesStore.vehicleDetails?.inspectionDate,
      renewalDate: fleetVehiclesStore.vehicleDetails?.inspectionRenewalDate,
      type: fleetVehiclesStore.vehicleDetails?.inspectionDocument?.type,
      hasDocument:
        !!fleetVehiclesStore.vehicleDetails?.inspectionDocument?.fileName ||
        false,
    },
    {
      field: t('Action_.buttons.insuranceFile'),
      date: '',
      renewalDate: fleetVehiclesStore.vehicleDetails?.insuranceRenewalDate,
      type: fleetVehiclesStore.vehicleDetails?.insuranceDocument?.type,
      hasDocument:
        !!fleetVehiclesStore.vehicleDetails?.insuranceDocument?.fileName ||
        false,
    },
  ];
});

const headers = computed(() => {
  return [
    {
      text: t('Msg_.file'),
      value: 'field',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },

    {
      text: t('Msg_.date'),
      value: 'date',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.renewalDate'),
      value: 'renewalDate',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
    {
      text: '',
      value: 'interact',
      class: 'uppercase-header',
      align: 'right',
      sortable: false,
    },
  ];
});
</script>

<style scoped lang="scss"></style>
