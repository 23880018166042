var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"data-testid":"fleet-ticket-form"}},[_c('v-dialog',{staticClass:"pa-4",attrs:{"value":_vm.displayDialog,"retain-focus":false,"persistent":"","width":"750"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-container',[_c('p',{staticClass:"card-title pa-0 mb-4 font-weight-bold"},[_vm._v(" "+_vm._s(_setup.t('Msg_.tickets.addTicket') + _setup.fleetVehiclesStore.vehicleDetails.plate)+" ")]),_c('v-form',{ref:"form",staticClass:"mt-6",model:{value:(_setup.valid),callback:function ($$v) {_setup.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.tickets.labels.refId') + '*',"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.tickets.labels.refId')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(_setup.ticketPayload.referenceNumber),callback:function ($$v) {_vm.$set(_setup.ticketPayload, "referenceNumber", $$v)},expression:"ticketPayload.referenceNumber"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"label":_setup.t('Msg_.tickets.labels.responsible'),"value":_setup.userStore.userInfo.name,"disabled":true}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c('p',{staticClass:"custom-label mb-1 mt-0"},[_vm._v(" "+_vm._s(_setup.t('Msg_.tickets.labels.employee') + '*')+" ")]),_c('v-autocomplete',{attrs:{"items":_setup.items,"search-input":_setup.search,"dense":"","outlined":"","hide-no-data":"","append-icon":"","prepend-inner-icon":"mdi-magnify","color":"blueRegular","item-text":"completeName","item-value":"id","rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.tickets.labels.employee')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},on:{"update:searchInput":function($event){_setup.search=$event},"update:search-input":function($event){_setup.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"employee-name"},[_vm._v(" "+_vm._s(data.item.completeName)+" ")]),_c('v-list-item-subtitle',{staticClass:"employee-office"},[_vm._v(" "+_vm._s(data.item.office)+" ")])],1)]}}]),model:{value:(_setup.ticketPayload.employeeId),callback:function ($$v) {_vm.$set(_setup.ticketPayload, "employeeId", $$v)},expression:"ticketPayload.employeeId"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c(_setup.DatePicker,{attrs:{"label":_setup.t('Msg_.tickets.labels.date') + '*',"max":_setup.nowDate,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.tickets.labels.date')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(_setup.ticketPayload.ticketDate),callback:function ($$v) {_vm.$set(_setup.ticketPayload, "ticketDate", $$v)},expression:"ticketPayload.ticketDate"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{attrs:{"label":_setup.t('Msg_.tickets.labels.type') + '*',"items":_setup.selectTicketType,"item-text":"text","item-value":"value","rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.tickets.labels.type')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(_setup.ticketPayload.ticketType),callback:function ($$v) {_vm.$set(_setup.ticketPayload, "ticketType", $$v)},expression:"ticketPayload.ticketType"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"6"}},[_c(_setup.FileInput,{attrs:{"label":_setup.t('Msg_.tickets.labels.file') + '*',"value":_setup.fileName,"placeholder":_vm.$helpers.shrinkString(_setup.fileName),"clearable":true,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.tickets.labels.file')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},on:{"input":function($event){return _setup.createDocument($event)}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c(_setup.TextArea,{attrs:{"label":_setup.t('Msg_.tickets.labels.comments')},model:{value:(_setup.ticketPayload.comment),callback:function ($$v) {_vm.$set(_setup.ticketPayload, "comment", $$v)},expression:"ticketPayload.comment"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-0 pt-0"},[_c('v-btn',{staticClass:"cancel-variant-btn ml-3",attrs:{"disabled":_setup.isTicketPostInProgress || _setup.isUploadInProgress},on:{"click":function($event){return _setup.closeDialog()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.cancel'))+" ")]),_c('v-btn',{staticClass:"main-action-btn ml-2",attrs:{"disabled":!_setup.valid || _setup.isTicketPostInProgress || _setup.isUploadInProgress,"loading":_setup.isTicketPostInProgress},on:{"click":function($event){return _setup.submit()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.save'))+" ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }