<template>
  <v-container class="pa-0">
    <v-data-table
      class="px-4"
      hide-default-footer
      :headers="headers"
      :items="fleetVehiclesStore.ticketList"
      :items-per-page="pagination.pageSize"
      :no-data-text="t('Msg_.noDataAvailable')"
      data-testid="fleet-tickets"
      :loading="loadingStore.isPartialLoading"
    >
      <template #[`item.ticketType`]="{ item }">
        <p class="mb-0">
          {{ t(`Action_.selectTicketType.${item.ticketType}`) }}
        </p>
      </template>
      <template #[`item.ticketDate`]="{ item }">
        <p class="mb-0">
          {{ helpers.formatShortDate(item.ticketDate) }}
        </p>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-menu bottom left offset-x>
          <template #activator="{ on, attrs }">
            <IconButton
              data-testid="ticket-list-menu-btn"
              :bind="attrs"
              :on="on"
              class="icon-btn"
              icon="mdi-dots-vertical"
            />
          </template>
          <v-list class="py-1 px-0">
            <v-list-item>
              <IconButton
                class="icon-btn"
                color="blueRegular"
                :disabled="loadingStore.isPartialLoading"
                icon="mdi-eye"
                :border="false"
                icon-size="21"
                @click="interactWithDocument(item.ticketURL)"
              />
            </v-list-item>
            <v-list-item>
              <IconButton
                class="icon-btn"
                color="blueRegular"
                :disabled="loadingStore.isPartialLoading"
                icon="mdi-download"
                :border="false"
                icon-size="21"
                @click="interactWithDocument(item.ticketURL, true)"
            /></v-list-item>
            <v-list-item v-if="canUser(['Fleet.Ticket.DeleteTicket'])">
              <IconButton
                class="icon-btn"
                color="redDanger"
                icon="mdi-delete"
                :disabled="loadingStore.isPartialLoading"
                :border="false"
                icon-size="21"
                @click="deleteTicket(item)"
            /></v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <Pagination
      class="px-4 mt-2"
      type="wide"
      v-model="pagination"
      @update-list="updateDocumentList()"
    />
    <FleetTicketForm
      v-if="displayDialog"
      :display-dialog="displayDialog"
      @close-ticket-dialog="closeTicketDialog"
    />
  </v-container>
</template>

<script setup>
import { defineEmits, defineProps, computed, onBeforeUnmount } from 'vue';
import { useLoadingStore } from '@/store/pinia/loading.store.js';
import { useFleetVehiclesStore } from '@/store/pinia/fleet-vehicles.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { usePermissions } from '@/helpers/composables/permissions.js';
import FleetTicketForm from './fleet-ticket-form.vue';
import helpers from '@/helpers/helpers';
import IconButton from '@/components/shared/customComponents/buttons/icon-button.vue';
import languages from '../../locales/languages';
import Pagination from '@/components/shared/customComponents/paginations/pagination.vue';
import { usePagination } from '@/helpers/composables/pagination.js';

const loadingStore = useLoadingStore();
const fleetVehiclesStore = useFleetVehiclesStore();
const { canUser } = usePermissions();
const { t } = useI18n({ locale: 'en', messages: languages });
const emit = defineEmits(['close-ticket-dialog']);
const {
  pagination,
  validatePaginationData,
  validatePageOptions,
  updatePaginationData,
} = usePagination();

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  displayDialog: {
    type: Boolean,
    required: true,
  },
});

// --- Methods ---
const getTicketList = async () => {
  validatePaginationData();
  const payload = {
    pagination: pagination.value,
    vehicleId: fleetVehiclesStore.vehicleDetails.id,
  };

  try {
    const response = await fleetVehiclesStore.getTicketsByVehicle(payload);
    updatePaginationData(response);
  } catch (error) {
    console.log(error);
  }
};

const deleteTicket = async (ticket) => {
  try {
    await fleetVehiclesStore.deleteTicket(ticket.id);
    await getTicketList();
  } catch (error) {
    console.log(error);
  }
};

const updateDocumentList = () => {
  validatePageOptions();
  getTicketList();
};

const interactWithDocument = (fileUrl, download) => {
  if (download) {
    window.open(
      `${process.env.VUE_APP_LOGIN_API}/v2/api/Vehicles/download-ticket?path=${fileUrl}&download=true`
    );
  } else {
    window.open(
      `${process.env.VUE_APP_LOGIN_API}/v2/api/Vehicles/download-ticket?path=${fileUrl}`,
      '_blank'
    );
  }
};

const closeTicketDialog = () => {
  emit('close-ticket-dialog');
  updateDocumentList();
};

// --- Computed ---
const headers = computed(() => {
  return [
    {
      text: t('Msg_.tickets.labels.refId'),
      value: 'referenceNumber',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },

    {
      text: t('Msg_.tickets.labels.type'),
      value: 'ticketType',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.tickets.labels.employee'),
      value: 'employee',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.tickets.labels.date'),
      value: 'ticketDate',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
    {
      text: t('Msg_.tickets.labels.responsible'),
      value: 'responsible',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
    {
      text: '',
      value: 'actions',
      class: 'uppercase-header',
      align: 'center',
      sortable: false,
    },
  ];
});

// --- Lifecycle hooks ---
getTicketList();
pagination.value.pageSize = 5;

onBeforeUnmount(() => {
  fleetVehiclesStore.ticketList = [];
});
</script>

<style scoped lang="scss">
.v-data-table::v-deep th {
  font-size: 11px !important;
}

.v-data-table::v-deep td .font-11 {
  font-size: 11px !important;
}
</style>
